export const STORE_TYPES = ["bakery", "pastry", "retailer"] as const;

export type StoreType = (typeof STORE_TYPES)[number];

export const DEFAULT_STORE_TYPE: StoreType = "bakery";

export const DEFAULT_OPENING_HOUR = 6;
export const DEFAULT_CLOSING_HOUR = 22;

export const DEFAULT_TIMEZONE = "Africa/Tunis";
